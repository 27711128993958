<template>
    <div class="pagebox">
        <!-- 顶部 -->
        <PHeader></PHeader>

        <!-- 院校排名 -->
        <div class="college_fixed_box">
            <div class="college_rank_fixed" id="college_rank_fixed">
                <div class="fixed_box">
                    <div class="area_box">
                        <div class="area_list" :style="{'height':AearHeight+'px'}" @mouseleave.prevent="Level1BoxLeave()"><!-- @mouseleave.prevent="Level1BoxLeave()" -->
                            <div class="major_now nowrap">当前专业：<b :title="SearchMajorName">{{SearchMajorName}}</b></div>
                            <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                                <div class="major_item" v-for="(item1,index1) in MajorTypeData" :key="index1" @mouseenter="Level1BoxEnter(item1)"><!-- mouseenter -->
                                    <span class="mname">{{item1.Name}}</span>
                                    <i class="el-icon-arrow-right"></i>
                                </div>
                                <div class="clear"></div>
                            </el-scrollbar>
                            <div class="level2_box" :style="{'height':(AearHeight-51)+'px'}" v-if="Level2BoxShow" @mouseleave.prevent="Level2BoxLeave()"><!-- @mouseleave.prevent="Level2BoxLeave()" -->
                                <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                                    <div class="major_item" v-for="(item2,index2) in Level2MajorData" :key="index2" @mouseenter="Level2BoxEnter(item2)"><!-- mouseenter" -->
                                        <span class="mname">{{item2.Name}}</span>
                                        <i class="el-icon-arrow-right"></i>
                                    </div>
                                    <div class="clear" style="height:20px;"></div>
                                </el-scrollbar>
                                <div class="level3_box" :style="{'height':(AearHeight-51)+'px'}" v-if="Level3BoxShow">
                                    <el-scrollbar wrap-class="scrollbar-wrapper" class="scrollbox">
                                        <div class="major_item" v-for="(item3,index3) in Level3MajorData" :key="index3" @click.prevent="MajorItemCheck(item3)">
                                            <span class="mname">{{item3.Name}}</span>
                                        </div>
                                        <div v-if="!Level3MajorData||Level3MajorData.length==0" style="color:#666;font-size:14px;text-align:center;line-height:80px;">未找到相关专业！</div>
                                        <div class="clear" style="height:20px;"></div>
                                    </el-scrollbar>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="type_box">
                        <div class="type_list" id="type_list_id">
                            <div class="i_box">
                                <div class="qbtn" @click.prevent="CollegeSearchBtn(1)">筛选条件<i class="el-icon-d-arrow-right"></i></div>
                                <div class="num">共找到 <span class="n">{{CollegeNumber}}</span> 所院校</div>
                                <div class="stxt">
                                    <el-input placeholder="搜索院校" v-model="searchForm.CollegeName" @keyup.enter.native="SeachFormSubmit" clearable></el-input>
                                </div>
                                <div class="s_btn" @click="SeachFormSubmit"><i class="el-icon-search"></i>搜 索</div>
                                <div class="clear"></div>
                            </div>
                            <div class="t_box" v-if="query.CollegeCityIdList.length!=0||query.CollegeCategoryIdList.length!=0||query.FeatureTagList.length!=0||query.StrengthTagList.length!=0">
                                <div class="ttxt">已选条件 ：</div>
                                <div class="t_data">
                                    <div class="data" v-if="query.CollegeCityIdList.length!=0">
                                        <span class="tit">院校地区：</span>
                                        <span class="d" v-for="itemOne in CollegeCityCheckList" :key="itemOne.ProvinceId">{{itemOne.ProvinceName}}<i class="el-icon-circle-close" @click.prevent="ProvinceRemove(itemOne.ProvinceId)"></i></span>
                                        <div class="close" @click.prevent="ProvinceRemove('')"><i class="el-icon-close"></i></div>
                                    </div>
                                    <div class="data" v-if="query.CollegeCategoryIdList.length!=0">
                                        <span class="tit">院校类别：</span>
                                        <span class="d" v-for="itemTwo in query.CollegeCategoryIdList" :key="itemTwo">{{showName(itemTwo, 2)}}<i class="el-icon-circle-close" @click.prevent="CollegeCategoryRemove(itemTwo)"></i></span>
                                        <div class="close" @click.prevent="CollegeCategoryRemove('')"><i class="el-icon-close"></i></div>
                                    </div>
                                    <div class="data" v-if="query.StrengthTagList.length!=0">
                                        <span class="tit">实力标签：</span>
                                        <span class="d" v-for="itemSix in query.StrengthTagList" :key="itemSix">{{showName(itemSix,6)}}<i class="el-icon-circle-close" @click.prevent="CollegeStrengthTagRemove(itemSix)"></i></span>
                                        <div class="close" @click.prevent="CollegeStrengthTagRemove('')"><i class="el-icon-close"></i></div>
                                    </div>
                                    <div class="data" v-if="query.FeatureTagList.length!=0">
                                        <span class="tit">特色标签：</span>
                                        <span class="d" v-for="itemFive in query.FeatureTagList" :key="itemFive">{{showName(itemFive,5)}}<i class="el-icon-circle-close" @click.prevent="CollegeFeatureTagRemove(itemFive)"></i></span>
                                        <div class="close" @click.prevent="CollegeFeatureTagRemove('')"><i class="el-icon-close"></i></div>
                                    </div>
                                    <div class="clear"></div>
                                </div>
                                <div class="clear"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="college_rank_box">
            <div class="college_rank_list">
                <div class="table_box" :style="table_box_style">
                    <div class="table">
                        <div class="college_item" v-for="item in CollegeRankData" :key="item.CollegeId">
                            <div class="logo" @click.prevent="CollegeDetail(item.CollegeId)" :style="{'background':'url('+item.CollegeLogoFileUrl+') no-repeat center center / cover'}"></div>
                            <div class="mark">
                                <div>
                                    <span class="name" @click.prevent="CollegeDetail(item.CollegeId)">{{item.CollegeName}}</span>
                                    <div class="con">
                                        <span class="txt"><i class="iconfont">&#xe615;</i>{{item.ProvinceName}}<span v-if="item.ProvinceName&&item.CityName"> · </span>{{item.CityName}}</span>
                                        <span class="txt"><i class="iconfont">&#xe679;</i>{{item.ChargeDept}}<span v-if="item.ChargeDept&&item.NatureName"> · </span>{{item.NatureName}}<span v-if="(item.ChargeDept||item.NatureName)&&item.CategoryName"> · </span>{{item.CategoryName}}</span>
                                    </div>
                                </div>
                                <div class="tips" :id="'college_tip_id'+item.CollegeId" v-if="item.StrengthTags||item.FeatureTags">
                                    <span v-for="t1 in GetTagsArr(item.StrengthTags)" :key="(t1+'')">{{t1}}</span>
                                    <span v-for="t2 in GetTagsArr(item.FeatureTags)" :key="(t2+'')">{{t2}}</span>
                                    <div class="more" :id="'college_tip_down'+item.CollegeId" @click.prevent="CollegeTipBtn(item.CollegeId,1)">展开 <i class="el-icon-arrow-down"></i></div>
                                    <div class="more" :id="'college_tip_up'+item.CollegeId" @click.prevent="CollegeTipBtn(item.CollegeId,2)" style="display:none;">收起 <i class="el-icon-arrow-up"></i></div>
                                </div>
                                <div class="level-list">
                                    <div class="it" v-for="(t, i) in item.RankItemList" :key="i">
                                        <div class="grade">{{t.Value}}</div>
                                        <div class="text">{{t.Name}}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="types order">
                                <div class="tit">专业排名</div>
                                <div class="info">第{{item.MajorRankNum}}名</div>
                                <div class="info">评级：<span v-if="item.MajorRankLevel">{{item.MajorRankLevel}}</span></div>
                            </div>
                            <!-- <div class="types">
                                <div class="tit">学科评估</div>
                                <div class="info">{{item.LearnSubjectEvaluateLevel}}</div>
                            </div> -->
                            <div class="types">
                                <div class="tit">总分</div>
                                <div class="info">{{item.MajorRankScore}}</div>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="pages" @click.prevent="pagesbtn()">
                            <i class="iconfont" v-if="CollegeRankData.length==0&&pageIndex==1">&#xe67c;</i>
                            <span>{{pagetxt}}</span>
                        </div>
                        <div class="clear"></div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>
        </div>
        <!-- 筛选弹层 -->
        <div class="query_layer_box" id="query_layer_box" @click.prevent="CloseSearchLayerOfBack()">
            <div class="m_box" @click.prevent.stop="">
                <div class="m_list" id="college_list_box">
                    <el-scrollbar wrap-class="scrollbar-wrapper" class="sx_scrollbar">
                        <div class="s_box">
                            <div class="tit">院校地区：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.CollegeCityIdList.length==0?'on':'')" @click.prevent="ProvinceCheck('')">不限</span>
                                <span class="item" :class="(queryCopy.CollegeCityIdList.find(f=>f==item1.ProvinceId)?'on':'')" v-for="item1 in CollegeScreen.CollegeAreaList" :key="item1.ProvinceId" @click.prevent="ProvinceCheck(item1)">{{item1.ProvinceName}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">院校类别：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.CollegeCategoryIdList.length==0?'on':'')" @click.prevent="CollegeCategoryCheck('')">不限</span>
                                <span class="item" :class="(queryCopy.CollegeCategoryIdList.find(f=>f==item2.Id)?'on':'')" v-for="item2 in CollegeScreen.CollegeCategoryList" :key="item2.Id" @click.prevent="CollegeCategoryCheck(item2)">{{item2.Name}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">实力标签：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.StrengthTagList.length==0?'on':'')" @click.prevent="CollegeStrengthTagCheck('')">不限</span>
                                <span class="item" :class="(queryCopy.StrengthTagList.find(f=>f==item6.Id)?'on':'')" v-for="item6 in CollegeScreen.StrengthTagList" :key="item6.Id" @click.prevent="CollegeStrengthTagCheck(item6)">{{item6.Name}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="s_box">
                            <div class="tit">特色标签：</div>
                            <div class="box">
                                <span class="item" :class="(queryCopy.FeatureTagList.length==0?'on':'')" @click.prevent="CollegeFeatureTagCheck('')">不限</span>
                                <span class="item" :class="(queryCopy.FeatureTagList.find(f=>f==item5.Id)?'on':'')" v-for="item5 in CollegeScreen.FeatureTagList" :key="item5.Id" @click.prevent="CollegeFeatureTagCheck(item5)">{{item5.Name}}</span>
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="clear" style="height:80px"></div>
                        <div class="btnbox">
                            <div class="s_back" @click.prevent="CloseSearchLayerOfBack()"><i class="el-icon-arrow-left" style="color:#999"></i> 返 回</div>
                            <div class="s_submit" @click.prevent="SeachFormSubmit"><i class="el-icon-search"></i> 查 询</div>
                        </div>
                    </el-scrollbar>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <PFooter></PFooter>
    </div>
</template>
<script>
import PHeader from "@/components/Header.vue";
import PFooter from "@/components/Footer.vue";
import API from "@/api/config";
import { getStore, setStore } from "@/utils/utils";
export default {
    components:{
        PHeader,
        PFooter
    },
    data() {
        return{
            loading:false,
            pageIndex:1,
            pageSize:10,
            pagetxt:'未查询到专业排名信息',
            HasNext:false,
            MajorTypeData:[],
            
            Level2BoxShow:false,
            Level3BoxShow:false,
            Level2MajorData:[],
            Level3MajorData:[],
            
            AearHeight:'586',
            
            CollegeNumber:0,
            SearchMajorName:'',
            searchForm:{
                MajorId:'',
                CollegeName:'',
                CollegeProvinceIdList:[],
                CollegeCategoryIdList:[],
                StrengthTagIdList:[],
                FeatureTagsIdList:[],
            },
            
            CollegeScreen: {},
            query:{
                CollegeCityIdList: [],// 院校地区
                CollegeCategoryIdList: [],// 院校类别
                StrengthTagList: [],// 实力标签
                FeatureTagList: [], // 特色标签
            },
            queryCopy:{
                CollegeCityIdList: [],// 院校地区
                CollegeCategoryIdList: [],// 院校类别
                StrengthTagList: [],// 实力标签
                FeatureTagList: [], // 特色标签
            },
            CollegeRankData:[],
            table_box_style:''
        }
    },
    mounted(){
        setStore("QueryCollegeStoreData",'');//清除筛选数据
        this.GetMajorTypeData();// 获取专业类别数据
        this.ResetOrClearSearchStoreData();// 缓存、重置 筛选信息
        this.getScreen()// 获取筛选数据
        
        //监听滚动事件
        window.addEventListener('scroll', () => {
            let ddeht = document.documentElement.scrollTop || document.body.scrollTop;
            let cln=document.getElementById('college_rank_fixed');
            if(cln){
                if (ddeht > 120) {
                    cln.style.position='fixed';
                    cln.style.top='40px';
                }
                else {
                    cln.style.position='initial';
                }
            }
            if((document.documentElement.offsetHeight-ddeht)<=988){
                this.AearHeight = document.documentElement.offsetHeight-ddeht-412;
            }
            else{
                this.AearHeight="586";
            }
        }, true);
        this.table_box_style='padding-top:'+(document.getElementById('type_list_id').clientHeight-8)+'px';
    },
    methods:{
        // 获取专业类别数据
        GetMajorTypeData(){
            API.Web.GetMajorTreeList({EducationLevel:1}).then(res => {
                if(res.data){
                    this.MajorTypeData = res.data;
                    if(res.data){
                        this.searchForm.MajorId = res.data[0].SubList[0].SubList[0].Id;
                        this.$nextTick(()=>{
                            this.SearchMajorName = res.data[0].SubList[0].SubList[0].Name;
                        })
                        this.GetMajorRankList();//获取专业排名列表
                    }
                }
            })
        },
        // 一级移上事件
        Level1BoxEnter(item){
            this.Level2BoxShow=true;
            this.Level2MajorData = item.SubList;
        },
        // 一级离开事件
        Level1BoxLeave(){
            this.Level2BoxShow=false;
        },
        // 二级移上事件
        Level2BoxEnter(item){
            this.Level3BoxShow=true;
            this.Level3MajorData = item.SubList;
        },
        // 二级离开事件
        Level2BoxLeave(){
            this.Level3BoxShow=false;
        },
        // 专业选择
        MajorItemCheck(item){
            this.Level2BoxShow=false;
            this.Level3BoxShow=false;
            this.searchForm.MajorId  = item.Id;
            this.$nextTick(()=>{
                this.SearchMajorName  = item.Name;
            })
            this.CollegeRankData=[];
            this.GetMajorRankList();//获取专业排名列表
        },
        // 筛选数据关闭、回滚
        CloseSearchLayerOfBack(){
            // 院校优先
            this.queryCopy.CollegeCityIdList = this.query.CollegeCityIdList;
            this.queryCopy.CollegeCategoryIdList = this.query.CollegeCategoryIdList;
            this.queryCopy.StrengthTagList = this.query.StrengthTagList;
            this.queryCopy.FeatureTagList = this.query.FeatureTagList;
            this.CollegeSearchBtn(2);//关闭筛选数据dom
        },
        // 省份选择
        ProvinceCheck(item){
            if(item!=''){
                let flag = this.queryCopy.CollegeCityIdList.includes(item.ProvinceId)
                if(flag){
                    this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!==item.ProvinceId);
                    this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.ProvinceId!=item.ProvinceId);// 移除dom选中的当前省份
                }
                else{
                    this.queryCopy.CollegeCityIdList.push(item.ProvinceId);
                    this.CollegeCityCheckListCopy.push({ProvinceId: item.ProvinceId,ProvinceName: item.ProvinceName});// 添加dom选中的当前省份
                }
            }
            else{
                this.queryCopy.CollegeCityIdList = [];
                this.CollegeCityCheckListCopy = [];
            }
        },
        // 省份移除
        ProvinceRemove(id){
            if(id!=''){
                this.queryCopy.CollegeCityIdList = this.queryCopy.CollegeCityIdList.filter(f=>f!==id);
                this.CollegeCityCheckListCopy = this.CollegeCityCheckListCopy.filter(f=>f.ProvinceId!=id);// 移除dom选中的当前省份
            }
            else{
                this.queryCopy.CollegeCityIdList = [];
                this.CollegeCityCheckListCopy = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 绑定已选省份
        ProvinceCheckBind(){
            this.CollegeCityCheckListCopy = [];
            this.CollegeScreen.CollegeAreaList.forEach(item=>{
                if(this.queryCopy.CollegeCityIdList.indexOf(item.ProvinceId)>-1){
                    this.CollegeCityCheckListCopy.push({ProvinceId: item.ProvinceId,ProvinceName: item.ProvinceName});
                }
            });
        },
        // 院校类别选择
        CollegeCategoryCheck(item){
            if(item!=''){
                let flag = this.queryCopy.CollegeCategoryIdList.includes(item.Id)
                if(flag){
                    this.queryCopy.CollegeCategoryIdList = this.queryCopy.CollegeCategoryIdList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.CollegeCategoryIdList.push(item.Id);
                }
            }
            else{
                this.queryCopy.CollegeCategoryIdList = [];
            }
        },
        // 院校类别移除
        CollegeCategoryRemove(id){
            if(id!=''){
                this.queryCopy.CollegeCategoryIdList = this.queryCopy.CollegeCategoryIdList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.CollegeCategoryIdList = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 院校实力标签选择
        CollegeStrengthTagCheck(item){
            if(item!=''){
                let flag = this.queryCopy.StrengthTagList.includes(item.Id)
                if(flag){
                    this.queryCopy.StrengthTagList = this.queryCopy.StrengthTagList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.StrengthTagList.push(item.Id);
                }
            }
            else{
                this.queryCopy.StrengthTagList = [];
            }
        },
        // 院校实力标签移除
        CollegeStrengthTagRemove(id){
            if(id!=''){
                this.queryCopy.StrengthTagList = this.queryCopy.StrengthTagList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.StrengthTagList = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 院校特色标签选择
        CollegeFeatureTagCheck(item){
            if(item!=''){
                let flag = this.queryCopy.FeatureTagList.includes(item.Id)
                if(flag){
                    this.queryCopy.FeatureTagList = this.queryCopy.FeatureTagList.filter(f=>f!==item.Id);
                }
                else{
                    this.queryCopy.FeatureTagList.push(item.Id);
                }
            }
            else{
                this.queryCopy.FeatureTagList = [];
            }
        },
        // 院校特色标签移除
        CollegeFeatureTagRemove(id){
            if(id!=''){
                this.queryCopy.FeatureTagList = this.queryCopy.FeatureTagList.filter(f=>f!==id);
            }
            else{
                this.queryCopy.FeatureTagList = [];
            }
            this.SeachFormSubmit();//刷新数据
        },
        // 筛选提交
        SeachFormSubmit(){
            this.ResetOrClearSearchStoreData();// 缓存、重置 筛选信息
            this.CollegeSearchBtn(2);//关闭院校筛选条件
            this.CollegeRankData=[];
            this.pageIndex=1;
            this.GetMajorRankList();//获取专业排名列表
            this.$nextTick(()=>{
                this.table_box_style='padding-top:'+(document.getElementById('type_list_id').clientHeight-8)+'px';
            });
        },
        // 缓存、重置 筛选信息
        ResetOrClearSearchStoreData(){
            setStore("QueryCollegeStoreData",this.queryCopy);
            // 院校优先
            this.query.CollegeCityIdList = this.queryCopy.CollegeCityIdList;
            this.query.CollegeCategoryIdList = this.queryCopy.CollegeCategoryIdList;
            this.query.StrengthTagList = this.queryCopy.StrengthTagList;
            this.query.FeatureTagList = this.queryCopy.FeatureTagList;
            this.CollegeCityCheckList = this.CollegeCityCheckListCopy;// 操作dom
        },
        // 获取筛选数据
        getScreen() {
            // 院校优先
            API.Web.GetCollegeQueryParameter().then(res => {
                this.CollegeScreen = res.Data;
                // 院校地区参数处理
                this.CollegeScreen.CollegeAreaList = this.CollegeScreen.CollegeAreaList||[];
            });
        },
        // 院校筛选条件 显示/隐藏
        CollegeSearchBtn(type){
            if(type==1){
                document.getElementById('query_layer_box').style.display='block';
                document.getElementById('query_layer_box').classList.add("layerbackshow");
                document.getElementById('query_layer_box').classList.remove("layerbackhide");
                let obj = document.getElementById("college_list_box");
                obj.style.display='block';
                obj.classList.remove("layerboxhide");
                obj.classList.add("layerboxshow");
                //绑定筛选信息
                let data = getStore("QueryCollegeStoreData",this.queryCopy);
                if(data){
                    data = JSON.parse(data);
                    // 院校优先
                    this.queryCopy.CollegeCityIdList = data.CollegeCityIdList;
                    this.queryCopy.CollegeCategoryIdList = data.CollegeCategoryIdList;
                    this.queryCopy.StrengthTagList = data.StrengthTagList;
                    this.queryCopy.FeatureTagList = data.FeatureTagList;
                    this.ProvinceCheckBind();// 绑定已选省份
                }
            }
            else{
                document.getElementById('query_layer_box').classList.add("layerbackhide");
                document.getElementById('query_layer_box').classList.remove("layerbackshow");
                let obj = document.getElementById("college_list_box");
                obj.classList.remove("layerboxshow");
                obj.classList.add("layerboxhide");
                let st = setTimeout(()=>{
                    document.getElementById('query_layer_box').style.display='none';
                    obj.style.display='none';
                    clearTimeout(st);
                },300);
            }
        },
        //获取院校排名列表
        GetMajorRankList(){
            // 处理筛选参数
            this.searchForm={
                CollegeProvinceIdList:this.query.CollegeCityIdList,
                CollegeCategoryIdList:this.query.CollegeCategoryIdList,
                StrengthTagIdList:this.query.StrengthTagList,
                FeatureTagsIdList:this.query.FeatureTagList,
                CollegeName:this.searchForm.CollegeName,
                MajorId: this.searchForm.MajorId
            };
            API.Query.GetMajorRankingList(this.pageIndex,this.pageSize,this.searchForm).then(res => {
                this.CollegeNumber = res.Data.Total;
                if(res.Data.Data.length>0){
                    res.Data.Data.forEach(f => {
                        this.CollegeRankData.push(f);
                    });
                    this.HasNext=res.Data.HasNext;
                    if(this.HasNext){
                        this.pagetxt='点击加载更多';
                    }
                    else{
                        this.pagetxt='已经到底啦！';
                    }
                    this.AearHeight="586";
                }
                else{
                    this.pagetxt='未找到院校排名信息！';
                }
            });
        },
        //加载更多
        pagesbtn(){
            if(this.HasNext){
                if(this.pagetxt=='点击加载更多'){
                    this.pageIndex+=1;
                    this.GetMajorRankList();
                }
            }else{
                if(this.CollegeRankData.length!=0){
                    this.pagetxt='已经到底啦！';
                }
            }
        },
        // 显示选中标签名称
        showName(id, type) {
            let name = ''
            switch (type) {
                case 2:
                this.CollegeScreen.CollegeCategoryList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 3:
                this.CollegeScreen.CollegeNatureList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 4:
                this.CollegeScreen.EnrollCategoryList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 5:
                this.CollegeScreen.FeatureTagList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
                case 6:
                this.CollegeScreen.StrengthTagList.some(it => {
                    if (it.Id === id) {
                        name = it.Name
                    }
                })
                break;
            }

            return name
        },
        //点击院校
        CollegeDetail(id){
            this.$router.push('/query/collegedetail?id='+id);
        },
        // 院校标签展开/收起
        CollegeTipBtn(id,type){
            let obj1 = document.getElementById('college_tip_id'+id).style;
            let obj2 = document.getElementById('college_tip_down'+id).style;
            let obj3 = document.getElementById('college_tip_up'+id).style;
            if(type==1){
                obj1.height='auto';obj1.overflow='initial';obj2.display='none';obj3.display='block';
            }
            else{
                obj1.height='24px';obj1.overflow='hidden';obj2.display='block';obj3.display='none';
            }
        },
        // 院校标签格式转换
        GetTagsArr(item){
            if(item){
                if(item.indexOf(',')!=-1){
                    return item.split(',');
                }
                else{
                    return [item];
                }
            }
        }
    }
}
</script>
<style scoped lang="less">
.college_fixed_box{
    width: 100%;
    height: 50px;
    .college_rank_fixed{
        position: initial;
        width: 100%;
        height: auto;
        background-color: #f5f5f5;
        z-index: 1;
        .fixed_box{
            max-width: 1420px;
            margin: auto;
            user-select: none;
            position: relative;
            height: 50px;
            .area_box{
                width: 200px;
                position: absolute;
                left: 20px;
                top: 0;
                z-index: 10;
                .area_list{
                    margin: 20px 0 0px;
                    border: 1px solid #ddd;
                    background-color: #fff;
                    .major_now{
                        height: 50px;
                        line-height: 50px;
                        color: #666;
                        font-size: 14px;
                        border-bottom: 1px solid #ddd;
                        padding: 0 10px;
                        overflow: hidden;
                    }
                    .scrollbox{
                        height: calc(100% - 51px);
                    }
                    .major_item{
                        padding: 0px 10px 0px 20px;
                        color: #666;
                        font-size: 14px;
                        border-bottom: 1px solid #ddd;
                        position: relative;
                        .mname{
                            display: inline-block;
                            width: calc(100% - 30px);
                            line-height: 20px;
                            margin: 10px 0 10px;
                        }
                        i{
                            font-size: 16px;
                            color: #b6b3b3;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            position: absolute;
                            top: calc(50% - 15px);
                            right: 0px;
                            z-index: 1;
                        }
                    }
                    .major_item:hover{
                        .mname{
                            color: rgba(var(--themecolor),0.8);
                        }
                        i{
                            color: rgba(var(--themecolor),0.8);
                        }
                    }
                }
                .level2_box{
                    position: absolute;
                    right: -201px;
                    width: 200px;
                    top: 71px;
                    z-index: 51;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    .scrollbox{
                        height: 100%;
                    }
                    .major_item{
                        padding: 0px 10px 0px 20px;
                        color: #666;
                        font-size: 14px;
                        border-bottom: 1px solid #ddd;
                        position: relative;
                        .mname{
                            display: inline-block;
                            width: calc(100% - 30px);
                            line-height: 20px;
                            margin: 10px 0 10px;
                        }
                        i{
                            font-size: 16px;
                            color: #b6b3b3;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            position: absolute;
                            top: calc(50% - 15px);
                            right: 0px;
                            z-index: 1;
                        }
                    }
                    .major_item:hover{
                        .mname{
                            color: rgba(var(--themecolor),0.8);
                        }
                        i{
                            color: rgba(var(--themecolor),0.8);
                        }
                    }
                }
                .level3_box{
                    position: absolute;
                    right: -201px;
                    width: 200px;
                    top: -1px;
                    z-index: 51;
                    background-color: #fff;
                    border: 1px solid #ddd;
                    .scrollbox{
                        height: 100%;
                    }
                    .major_item{
                        padding: 0px 10px 0px 20px;
                        color: #666;
                        font-size: 14px;
                        border-bottom: 1px solid #ddd;
                        position: relative;
                        .mname{
                            display: inline-block;
                            width: calc(100% - 30px);
                            line-height: 20px;
                            margin: 10px 0 10px;
                        }
                        i{
                            font-size: 16px;
                            color: #b6b3b3;
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            text-align: center;
                            position: absolute;
                            top: calc(50% - 15px);
                            right: 0px;
                            z-index: 1;
                        }
                    }
                    .major_item:hover{
                        cursor: pointer;
                        .mname{
                            color: rgba(var(--themecolor),0.8);
                        }
                    }
                }
            }
            .type_box{
                width: calc(100% - 260px);
                height: 84px;
                position: absolute;
                right: 20px;
                top: 0;
                z-index: 9;
                .type_list{
                    border: 1px solid #ddd;
                    margin: 20px 0 0;
                    min-height: 60px;
                    background-color: #fff;
                    padding: 0 10px;
                    .i_box{
                        margin-top: 10px;
                        .qbtn{
                            width: 100px;
                            float: left;
                            color: #666;
                            font-size: 15px;
                            line-height: 38px;
                            border: 1px solid #ddd;
                            border-radius:5px;
                            text-align:center;
                            i{
                                margin-left: 2px;
                                color: rgba(var(--themecolor), 0.8);
                            }
                        }
                        .qbtn:hover{
                            cursor: pointer;
                            color: rgba(var(--themecolor), 1);
                            border: 1px solid rgba(var(--themecolor), 0.5);
                            i{
                                color: rgba(var(--themecolor), 1);
                            }
                        }
                        .num{
                            float: left;
                            width: 150px;
                            line-height: 40px;
                            color: #666;
                            font-size: 12px;
                            text-align: center;
                            padding-right: 10px;
                            .n{
                                font-size: 14px;
                                color: rgba(var(--themecolor),0.75);
                            }
                        }
                        .stxt{
                            position: relative;
                            float: left;
                            width: calc(100% - 475px);
                            height: 40px;
                            input{
                                display: block;
                                width: 100%;
                                height: 100%;
                                line-height: 38px;
                                border: none;
                                line-height: 1;
                                text-indent: 20px;
                                color: #666;
                                font-size: 14px;
                                background-color: #fff;
                            }
                            i{
                                position: absolute;
                                right: 0px;
                                top: 0px;
                                z-index: 1;
                                width: 38px;
                                height: 100%;
                                text-align: center;
                                line-height: 38px;
                                color: #999;
                                font-size: 16px;
                            }
                            i:hover{
                                cursor: pointer;
                                color: #666;
                            }
                        }
                        .s_btn{
                            width: 200px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            color: #fff;
                            font-size: 15px;
                            border-radius: 3px;
                            background-color: rgba(var(--themecolor),0.75);
                            float: right;
                            transition: all 0.2s;
                            -webkit-transition: all 0.2s;
                            i{
                                margin-right: 10px;
                            }
                        }
                        .s_btn:hover{
                            cursor: pointer;
                            background-color: rgba(var(--themecolor),1);
                        }
                    }
                    .t_box{
                        margin-top: 10px;
                        .ttxt{
                            width: 90px;
                            float: left;
                            color: #666;
                            font-size: 15px;
                            line-height: 38px;
                        }
                        .t_data{
                            width: calc(100% - 90px);
                            float: right;
                            .data{
                                float: left;
                                border: 1px solid rgba(var(--themecolor),0.75);
                                border-radius: 3px;
                                position: relative;
                                overflow: hidden;
                                margin: 0px 15px 10px 0px;
                                padding: 4px 45px 4px 10px;
                                .tit{
                                    color: #666;
                                    font-size: 14px;
                                }
                                .d{
                                    display: inline-block;
                                    color: #666;
                                    font-size: 12px;
                                    border: 1px solid #ddd;
                                    border-radius: 20px;
                                    background-color: #fff;
                                    height: 20px;
                                    line-height: 20px;
                                    padding: 0px 5px 0px 12px;
                                    margin: 4px 4px;
                                    i{
                                        margin-left: 6px;
                                        color: #999;
                                        font-size: 14px;
                                    }
                                    i:hover{
                                        color: rgba(var(--themecolor),1);
                                        cursor: pointer;
                                    }
                                }
                                .close{
                                    width: 40px;
                                    text-align: center;
                                    height: 100%;
                                    position: absolute;
                                    right: 0;
                                    top:0;
                                    background-color: rgba(var(--themecolor),0.75);
                                    i{
                                        display: block;
                                        color: #f0ecec;
                                        font-size:20px;
                                        height: 20px;
                                        line-height: 20px;
                                        width: 100%;
                                        position: relative;
                                        top: calc(50% - 10px);
                                        transition: all 0.2s;
                                        -webkit-transition: all 0.2s;
                                    }
                                }
                                .close:hover{
                                    cursor: pointer;
                                }
                                .close:hover i{
                                    transform: rotate(180deg);
                                    -webkit-transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
.college_rank_box{
    max-width: 1420px;
    margin: auto;
    user-select: none;
    .college_rank_list{
        width: calc(100% - 262px);
        min-height: 600px;
        float: right;
        margin-right: 22px;
        .table_box{
            .table{
                width: 100%;
                min-height: 400px;
                background-color: #fff;
                border: 1px solid #ddd;
                margin-bottom: 30px;
                .college_item{
                    margin: 10px;
                    border: 1px solid #e7e5e5;
                    box-shadow: 0px 0px 3px 1px #ebeaea;
                    border-radius: 5px;
                    overflow: hidden;
                    background-color: #fff;
                    .logo{
                        width: 80px;
                        height: 80px;
                        overflow: hidden;
                        margin: 20px;
                        float: left;
                        cursor: pointer;
                    }
                    .logo:hover{
                        opacity: 0.8;
                    }
                    .mark{
                        float: left;
                        margin-top: 15px;
                        width: calc(100% - 690px);
                        .name{
                            color: #666;
                            font-size: 18px;
                            margin-right: 20px;
                            font-weight: bold;
                            letter-spacing: 1px;
                            cursor: pointer;
                        }
                        .name:hover{
                            color: rgba(var(--themecolor),0.75);
                        }
                        .con{
                            margin-top: 5px;
                        }
                        .txt{
                            color: #666;
                            font-size: 14px;
                            margin-right: 20px;
                            i{
                                margin-right: 5px;
                                color: #a39f9f;
                                font-size: 13px;
                            }
                        }
                        .tips{
                            margin-top: 10px;
                            height: 24px;
                            overflow: hidden;
                            position: relative;
                            padding-right: 60px;
                            margin-bottom: 10px;
                            span{
                                display: inline-block;
                                padding: 1px 10px;
                                background-color: rgba(var(--themecolor),0.1);
                                color: #777575;
                                font-size: 12px;
                                margin: 0px 6px 5px 0px;
                                border-radius: 20px;
                            }
                            .more{
                                position: absolute;
                                right: 10px;
                                top: 0;
                                width: 60px;
                                height: 24px;
                                line-height: 24px;
                                text-align: center;
                                background-color: #fff;
                                color: #888;
                                font-size: 12px;
                            }
                            .more:hover{
                                cursor: pointer;
                                color: rgba(var(--themecolor),0.75);
                            }
                        }

                        .level-list {
                            margin-bottom: 5px;
                            display: flex;
                            .it {
                                padding: 10px;
                                margin-right: 2px;
                                border-radius: 5px;
                                text-align: center;
                                font-size: 13px;
                                background-image: linear-gradient(135deg, rgba(248, 248, 248, 1), rgba(129, 129, 194, 0.2));
                                .text {
                                    color: #666;
                                }
                            }
                        }
                    }
                    .types{
                        width: 150px;
                        float: left;
                        text-align: center;
                        .tit{
                            color: #666;
                            font-size: 14px;
                            margin-top: 20px;
                            margin-bottom: 15px;
                        }
                        .info{
                            color: #666;
                            font-size: 14px;
                            margin-bottom: 5px;
                        }
                    }
                    .order{
                        width: 250px;
                    }
                }
                .pages{
                    width: 100%;
                    min-height: 50px;
                    line-height: 50px;
                    color: #666;
                    font-size: 16px;
                    text-align: center;
                    letter-spacing: 1px;
                    margin-bottom: 10px;
                    user-select: none;
                    .iconfont{
                        font-size: 50px;
                        display: block;
                        margin-top: 40px;
                        color: #9b9b9b;
                    }
                }
                .pages:hover{
                    color: rgb(var(--themecolor));
                    cursor: pointer;
                }
            }
        }
    }
}
.query_layer_box{
    position: fixed;
    left: 0;
    top: 0;
    z-index: 19;
    width: 100%;
    height: 100%;
    display: none;
    opacity: 0;
    background-color: rgba(0,0,0,0.5);
    .m_box{
        margin: 50px auto 0;
        max-width: 1420px;
        height: calc(100% - 100px);
        position: relative;
        .m_list{
            border: 1px solid #ddd;
            margin: 0 20px;
            background-color: #ffffff;
            box-shadow: 0px 3px 5px 0px #d4d2d2;
            border-radius: 5px;
            z-index: 2;
            opacity: 0;
            display: none;
            height: 100%;
            .sx_scrollbar{
                width: 100%;
                height: 100%;
            }
            .s_box{
                margin: 0 20px 15px 20px;
                border-bottom: 1px dashed #c4c2c2;
                padding-bottom: 5px;
                .tit{
                    color: #666;
                    font-size: 14px;
                    float: left;
                    width: 80px;
                    text-align: right;
                }
                .box{
                    width: calc(100% - 100px);
                    float: right;
                    .item{
                        display: inline-block;
                        color: #666;
                        font-size: 12px;
                        padding: 2px 18px;
                        border: 1px solid #e2e2e2;
                        border-radius: 20px;
                        margin: 0px 8px 10px;
                        cursor: pointer;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                    }
                    .item:hover{
                        border: 1px solid rgba(var(--themecolor),0.75);
                        color: rgba(var(--themecolor),0.75);
                    }
                    .item.on{
                        border: 1px solid rgba(var(--themecolor),0.75);
                        background-color: rgba(var(--themecolor),0.75);
                        color: #fff;
                    }
                }
            }
            .s_box:nth-child(1){
                margin-top: 20px;
            }
            .level {
                .level-title {
                    padding-left: 60px;
                    color: #666;
                    font-size: 14px;
                    width: 80px;
                    text-align: right;
                }
                .level-box {
                    .level-item {
                        color: #666;
                        font-size: 12px;
                        padding: 2px 18px;
                        border: 1px solid #e2e2e2;
                        border-radius: 20px;
                        margin: 0px 8px 10px;
                        transition: all 0.2s;
                        -webkit-transition: all 0.2s;
                        position: relative;
                        &:hover {
                            border: 1px solid rgba(var(--themecolor),0.75);
                            cursor: pointer;
                            color: rgba(var(--themecolor),0.75);
                        }
                    }
                    .level-item.on {
                        border: 1px solid rgba(var(--themecolor),0.75);
                        background-color: rgba(var(--themecolor),0.75);
                        color: #fff;
                    }
                }
            }
            .btnbox{
                width: 100%;
                height: 60px;
                background-color: #fff;
                border-top: 1px solid #ddd;
                position: absolute;
                left: 0;
                bottom: 0;
                z-index: 2;
                text-align: center;
                .s_back{
                    width: 200px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 3px;
                    background-color: #e0e0e0;
                    color: #666;
                    font-size: 15px;
                    letter-spacing: 2px;
                    text-align: center;
                    margin: 10px 20px;
                    display: inline-block;
                }
                .s_back:hover{
                    cursor: pointer;
                    background-color: #d6d6d6;
                }
                .s_submit{
                    width: 200px;
                    height: 40px;
                    line-height: 40px;
                    border-radius: 3px;
                    background-color: rgba(var(--themecolor),0.75);
                    color: #fff;
                    font-size: 15px;
                    letter-spacing: 2px;
                    text-align: center;
                    margin: 10px 20px;
                    display: inline-block;
                }
                .s_submit:hover{
                    cursor: pointer;
                    background-color: rgba(var(--themecolor),1);
                }
            }
        }
        .m_list.layerboxshow {
            animation: layerboxshow 0.3s ease 0s 1;
            -webkit-animation: layerboxshow 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes layerboxshow {
                from {
                    top: -100px;
                    opacity: 0;
                }
                to {
                    top: 39px;
                    opacity: 1;
                }
            }
            @keyframes layerboxshow {
                from {
                    top: -100px;
                    opacity: 0;
                }
                to {
                    top: 39px;
                    opacity: 1;
                }
            }
        }
        .m_list.layerboxhide {
            animation: layerboxhide 0.3s ease 0s 1;
            -webkit-animation: layerboxhide 0.3s ease 0s 1;
            animation-fill-mode: forwards;
            -webkit-animation-fill-mode: forwards;
            @-webkit-keyframes layerboxhide {
                from {
                    top: 39px;
                    opacity: 1;
                }
                to {
                    top: -100px;
                    opacity: 0;
                }
            }
            @keyframes layerboxhide {
                from {
                    top: 39px;
                    opacity: 1;
                }
                to {
                    top: -100px;
                    opacity: 0;
                }
            }
        }
    }
}
.query_layer_box.layerbackshow {
    animation: layerbackshow 0.3s ease 0s 1;
    -webkit-animation: layerbackshow 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
    @keyframes layerbackshow {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}
.query_layer_box.layerbackhide {
    animation: layerbackhide 0.3s ease 0s 1;
    -webkit-animation: layerbackhide 0.3s ease 0s 1;
    animation-fill-mode: forwards;
    -webkit-animation-fill-mode: forwards;
    @-webkit-keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
    @keyframes layerbackhide {
        from {
            opacity: 1;
        }
        to {
            opacity: 0;
        }
    }
}
</style>
<style lang="less">
.college_fixed_box .college_rank_fixed .el-scrollbar__wrap{
    overflow-x: hidden;
}
.college_fixed_box .type_box .type_list .el-input.is-active .el-input__inner, .college_fixed_box .type_box .type_list .el-input .el-input__inner:focus{
    border: 1px solid rgba(var(--themecolor),0.75);
}
</style>